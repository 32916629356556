//COMPRAS
export const CREAR_SOLICITUD_COMPRAS = "2";//CREAR_SOLICITUD_COMPRAS
export const EDITAR_SOLICITUD_COMPRAS = "5"; //EDITAR_SOLICITUD_COMPRAS
export const ELIMINAR_SOLICITUD_COMPRAS = "6"; //ELIMINAR_SOLICITUD_COMPRAS
export const ENVIAR_SOLICITUD_COMPRAS = "7"; //ENVIAR_SOLICITUD_COMPRAS
export const VER_COMPRAS = "1";//VER_COMPRAS
export const APROBAR_RECHAZAR_SOLICITUD_COMPRAS =
  "3"; //APROBAR_RECHAZAR_SOLICITUD_COMPRAS
export const SUBIR_COTIZACIONES_COMPRAS = "4";//SUBIR_COTIZACIONES_COMPRAS
export const SUBIR_COMPROBANTES_PAGO = "8";//SUBIR_COMPROBANTES_PAGO
export const ENTREGADA_BODEGA = "10"; //ENTREGADA_BODEGA
export const CERRAR_SOLICITUD = "9"; //CERRAR_SOLICITUD
export const VISUALIZAR_SOLICITUD = "11"; //VISUALIZAR_SOLICITUD VER_SOLICITUD
export const RECHAZAR_COTIZACION_COMPRAS = "15"; //RECHAZAR_COTIZACION_COMPRAS
export const VER_HISTORIAL_COMPRAS = "16"; //VER_HISTORIAL_COMPRAS 

//CONTROL DE PERSONAL
export const VER_CONTROL_PERSONAL = "12"; //VER_CONTROL_PERSONAL

//HISTORIAL DE PAGOS
export const VER_HISTORIAL_PAGOS = "13"; //VER_HISTORIAL_PAGOS

//REEMBOLSOS
export const VER_REEMBOLSOS = "14"; //VER_REEMBOLSOS
